import React from 'react';
import { GenericCard } from "./genericcard";
import Swiper from 'react-id-swiper';
import "./cardsgallery.scss";
import { CardListTypes } from "../../assets/js/utils"
import { getTrimmedCardObjectByStyle } from "./genericcardlist"
export default function GenericCardGallery(props) {
    let activeSlideId = 0;
    const uniqueNavID = props.title.replace(/[^A-Z0-9]/ig, "_");
    const setFadeClass = () => {
        try {
            const fadeClass = "fade-card";
            let el = document.querySelector(".gallery .swiper-slide-active");
            activeSlideId = parseInt(el.id);
            let elToFade = document.getElementById(activeSlideId + 3);
            if (elToFade)
                elToFade.classList.add(fadeClass);
        }
        catch (e) {
            console.log(e);
        }
    }
    const removeFadeClass = () => {
        try {
            const fadeClass = "fade-card";
            const elToRemoveClass = document.querySelector("." + fadeClass);
            if (elToRemoveClass) {
                elToRemoveClass.classList.remove(fadeClass);
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const SLIDES_TO_SHOW = props.DEVICE_VIEW.IsMobile ? 1 : 3;
    const SPACE_BETWEEN = props.DEVICE_VIEW.IsMobile ? 15 : 20;
    const params = {
        pagination: {
            el: '#gallery-pagination',
            clickable: true
        },
        slidesPerGroup: SLIDES_TO_SHOW,
        slidesPerView: SLIDES_TO_SHOW,
        spaceBetween: SPACE_BETWEEN
    }
    if (!props.DEVICE_VIEW.IsMobile) {
        params.navigation = {
            nextEl: `#${uniqueNavID}-gallery-next`,
            prevEl: `#${uniqueNavID}-gallery-prev`,
        }
        params.on = {
            transitionEnd: setFadeClass,
            transitionStart: removeFadeClass,
            init: setFadeClass
        }
    }

    let cardList = [];
    let cards = props.cards;
    
    if (cards)
        cards.map((card, index) => {
            if ((index < 3 && props.DEVICE_VIEW.IsMobile) || !props.DEVICE_VIEW.IsMobile)
                cardList.push(<div className={`card-col`} key={index} id={index} >
                    <GenericCard data={getTrimmedCardObjectByStyle(props.theme, card)} cardStyle={CardListTypes[props.theme]} showArrow={true} allSitePage={props.allSitePage} withoutFade={true} /></div>);
            return null;
        });
    return (
        <>
            <div className="container">
                {props.title && <div className="card-head">
                    <h2 className="section-title has-top-line text-center">{props.title}</h2>
                </div>}
                <div className="gallery">
                    {!props.DEVICE_VIEW.IsMobile && <div className="gallery-navs">
                        <div
                            id={`${uniqueNavID}-gallery-prev`}
                            className="gallery-nav gallery-nav-prev">
                            <i className="icon icon-direction-long-left"></i>
                        </div>
                        <div
                            id={`${uniqueNavID}-gallery-next`}
                            className="gallery-nav gallery-nav-next">
                            <i className="icon icon-direction-long-right"></i>
                        </div>
                    </div>}
                    <Swiper {...params} >
                        {cardList}
                    </Swiper>
                    <div id="gallery-pagination" className="gallery-pagination"></div>
                </div>
            </div>
        </>

    )
}
