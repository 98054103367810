import React, { useState, useEffect } from "react";
import { GenericCard } from "./genericcard";
import {
  CardListTypes,
  getPagePathBySlug,
  CardWrapperCSS,
  getParameterByName,
} from "../../assets/js/utils";
import CollapsibleFilterMenu from "./collapsiblefiltermenu";
import CardDetailView from "./carddetailview";
import { getTrimmedCardObjectByStyle } from "./genericcardlist";
import scrollToElement from "scroll-to-element";
import useLocalStorage from "../Hooks";
function DesktopCardListView(props) {
  const [activeCard, updateActiveCard] = useState(null);
  let { allSitePage, cards, ALL_FILTER_TEXT } = props;
  const SCROLL_OFFSET = -36; // Used to adjust card information section, when a page does not have filter menu

  const getCardDetailPanelIfRequired = (card) => {
    let detailComp = null;
    if (
      activeCard &&
      activeCard.id === card.id &&
      activeCard.navigateToPage &&
      activeCard.navigateToPage.components
    ) {
      const cardNavUrl = getPagePathBySlug(
        activeCard.navigateToPage.slug,
        props.allSitePage.nodes
      );
      detailComp = (
        <CardDetailView
          key={`det-${activeCard.id}`}
          cardId={`det-${activeCard.id}`}
          learnMoreUrl={cardNavUrl}
          components={activeCard.navigateToPage.components}
          onCloseCallback={() => {
            onCloseCallback();
          }}
        ></CardDetailView>
      );
    }
    return detailComp;
  };
  const onCardClickCallback = (card) => {
    updateActiveCard(card);
  };
  const onCloseCallback = () => {
    if (activeCard !== null) {
      let navEl = document.querySelector(".collapsible-filter-menu");
      scrollToElement(`#${activeCard.id}`, {
        offset: navEl ? -navEl.scrollHeight : SCROLL_OFFSET,
        duration: 200,
      });
    }
    updateActiveCard(null);
  };
  useEffect(() => {
    if (activeCard) {
      let navEl = document.querySelector(".collapsible-filter-menu");
      scrollToElement(`#det-${activeCard.id}`, {
        offset: navEl ? -navEl.scrollHeight : SCROLL_OFFSET,
        ease: "out-bounce",
        duration: 200,
      });
    }
  }, [activeCard]);
  useEffect(() => {
    if (activeCard) {
      updateActiveCard(null); //reset active card on load/filter change
    }
  }, [cards]);
  let CARDS_TO_DISPLAY = props.DEVICE_VIEW.IsTablet ? 2 : 4;
  const getDesktopCardsList = () => {
    let rows = [];
    if (cards) {
      let loopCount = Math.floor(cards.length / CARDS_TO_DISPLAY);
      for (let i = 0; i <= loopCount; i++) {
        let columns = [];
        let cardDetails = [];
        for (let j = 0; j < CARDS_TO_DISPLAY; j++) {
          const index = j + i * CARDS_TO_DISPLAY;
          let card = cards[index];
          if (card) {
            card.id = "card-" + index;
            columns.push(
              <div className="col card-col" key={index}>
                <GenericCard
                  allSitePage={allSitePage}
                  data={getTrimmedCardObjectByStyle(props.theme, card)}
                  cardStyle={CardListTypes[props.theme]}
                  onCardClickCallback={onCardClickCallback}
                  isActive={activeCard != null && activeCard.id === card.id}
                  ALL_FILTER_TEXT={ALL_FILTER_TEXT}
                />
              </div>
            );
            cardDetails.push(getCardDetailPanelIfRequired(card));
          }
        }
        let columnsWrap = (
          <div key={i} className={CardWrapperCSS[props.theme]}>
            {columns}
          </div>
        );
        rows.push(columnsWrap);
        rows.push(cardDetails);
      }
    }
    return rows;
  };
  return <>{getDesktopCardsList()}</>;
}
export default function GenericCardListComp(props) {
  const [isProductsPage, setIsProductsPage] = useState(false);
  const [selectedCategoryState, setSelectedCategoryState] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [compState, updateCompState] = useState({
    categoriesList: null,
    cardsListToShow: null,
  });
  const NUMBER_OF_CARDS_TO_DISPLAY_ON_MOBILE = 4;
  const [cardsToShow, updateCardsToShow] = useState(
    NUMBER_OF_CARDS_TO_DISPLAY_ON_MOBILE
  ); //default 4 cards to show on mobile
  let { showFilterMenu, allSitePage } = props;
  const ALL_FILTER_TEXT = props.allFilterText ? props.allFilterText : "All";
  const initialState = {
    selectedCategory: ALL_FILTER_TEXT,
    contextualBreadcrumb: false,
  };
  const [globalState, setGlobalState] = useLocalStorage(
    "globalState",
    window.localStorage.hasOwnProperty("globalState")
      ? globalState
      : initialState
  );
  let _REFRESH = true;
  useEffect(() => {
    //load cards and categories
    let qsCategory = getParameterByName("c");
    if (qsCategory) initialState.selectedCategory = qsCategory;

    const path = window.location.pathname.split("/");
    const pagenameSlug = path[path.length - 1];
    const pathnameContainsCategory = props.categoryOrder
      ? props.categoryOrder.filter(
          (category) =>
            category.navigateToPage &&
            category.navigateToPage.slug === pagenameSlug
        )
      : false;
    const category_ =
      pathnameContainsCategory.length > 0
        ? pathnameContainsCategory[0].category
        : null;
    setSelectedCategoryState(category_);
    setIsProductsPage(pathnameContainsCategory.length > 0 ? true : false);

    if (globalState.selectedCategory !== initialState.selectedCategory) {
      _REFRESH = true;
      setGlobalState(initialState); //initialize with query string parameter or default ALL
    }
  }, []);

  useEffect(() => {
    // To check if there is only one product in a category
    if(selectedCategoryState === null) {
      let _filteredCategory = [];
      props.categoryOrder && props.categoryOrder.map(selectedCategory => {
        if (!compState.categoriesList || _REFRESH) {
          _REFRESH = false;
          let _categoriesList = [ALL_FILTER_TEXT];
          let _cardsListToShow = [];
          let _cardCategories = [];
          if (props.cards) {
            props.cards.forEach((card) => {
              if (
                selectedCategory.category === ALL_FILTER_TEXT ||
                !selectedCategory.category ||
                (selectedCategory.category &&
                  card.categories &&
                  card.categories.filter(function(e) {
                    return e.category === selectedCategory.category;
                  }).length > 0)
              )
                _cardsListToShow.push(card);

              const cardCategories = card.categories;
              if (cardCategories)
                cardCategories.forEach(function(cat) {
                  if (_cardCategories.indexOf(cat.category) === -1) {
                    _cardCategories.push(cat.category);
                  }
                });
            });
            _categoriesList = _categoriesList.concat(
              getCategoriesList(props.categoryOrder, _cardCategories)
            );
            
            if(_cardsListToShow && _cardsListToShow.length === 1) {
              const singleProduct = _cardsListToShow[0];
              selectedCategory.navigateToPage = singleProduct.navigateToPage ? singleProduct.navigateToPage : selectedCategory.navigateToPage;
            }

            _filteredCategory.push(selectedCategory)
          }
        }
      })
      setFilteredCategory(_filteredCategory);
    }

    //load cards and categories
    if (selectedCategoryState !== null) {
      // let selectedCategory = globalState.selectedCategory;
      let selectedCategory = selectedCategoryState;
      if (!compState.categoriesList || _REFRESH) {
        _REFRESH = false;
        let _categoriesList = [ALL_FILTER_TEXT];
        if (props.cards) {
          let _cardsListToShow = [];
          let _cardCategories = [];
          props.cards.forEach((card) => {
            if (
              selectedCategory === ALL_FILTER_TEXT ||
              !selectedCategory ||
              (selectedCategory &&
                card.categories &&
                card.categories.filter(function(e) {
                  return e.category === selectedCategory;
                }).length > 0)
            )
              _cardsListToShow.push(card);

            const cardCategories = card.categories;
            if (cardCategories)
              cardCategories.forEach(function(cat) {
                if (_cardCategories.indexOf(cat.category) === -1) {
                  _cardCategories.push(cat.category);
                }
              });
          });
          _categoriesList = _categoriesList.concat(
            getCategoriesList(props.categoryOrder, _cardCategories)
          );
          updateCompState({
            categoriesList: _categoriesList,
            cardsListToShow: _cardsListToShow,
          });
        }
      }
    } else if (!props.categoryOrder) {
      updateCompState({
        cardsListToShow: props.cards,
      });
    }
  }, [selectedCategoryState]);
  const onFilterCallback = (sCategory) => {
    globalState.selectedCategory = sCategory;
    setGlobalState(globalState);
    if (props.cards) {
      let _cardsListToShow = [];
      props.cards.forEach((card) => {
        if (
          sCategory === ALL_FILTER_TEXT ||
          (sCategory &&
            card.categories &&
            card.categories.filter(function(e) {
              return e.category === sCategory;
            }).length > 0)
        )
          _cardsListToShow.push(card);
      });
      updateCompState({
        categoriesList: compState.categoriesList,
        cardsListToShow: _cardsListToShow,
      });
    }
    let navEl = document.querySelector(".collapsible-filter-menu");
    scrollToElement(`#card-list-wrapper`, {
      offset: -navEl.scrollHeight,
      duration: 300,
    });
  };
  const getCategoriesList = (orderedCategories, cardCategories) => {
    let _categoriesList = [];
    if (orderedCategories) {
      orderedCategories.forEach((category) => {
        if (
          cardCategories.includes(category.category) &&
          _categoriesList.indexOf(category.category) === -1
        ) {
          _categoriesList.push(category.category);
        }
      });
    }
    cardCategories.forEach((category) => {
      if (_categoriesList.indexOf(category) === -1)
        _categoriesList.push(category);
    });
    return _categoriesList;
  };

  return (
    <>
      {/* {showFilterMenu &&
                <CollapsibleFilterMenu categoriesList={compState.categoriesList} {...props} onFilterCallback={onFilterCallback} selectedCategory={globalState.selectedCategory} ></CollapsibleFilterMenu>} */}

      {!showFilterMenu && props.title && (
        <div className={`card-head ${isProductsPage ? 'grey-container' : ''}`}>
          <h2 className="section-title has-top-line text-center mb-0">
            {props.title}
          </h2>
        </div>
      )}
      <div id="cards-content-container" className={`${isProductsPage || props.categoryOrder ? 'background-overlay' : ''}`}>
        <div className="container" id="card-list-wrapper">
          {/* Mobile View Products */}
          {(isProductsPage || !props.categoryOrder) &&
            (props.DEVICE_VIEW.IsMobile ||
              CardListTypes[props.theme] !== CardListTypes["Card Style 3"]) && (
              <>
                <div className={CardWrapperCSS[props.theme]}>
                  {compState.cardsListToShow &&
                    compState.cardsListToShow.map(function(card, i) {
                      if (props.DEVICE_VIEW.IsMobile && i + 1 > cardsToShow)
                        return null; //show upto 4 cards by default on mobile
                      return (
                        <div className="col card-col" key={i}>
                          <GenericCard
                            allSitePage={allSitePage}
                            data={getTrimmedCardObjectByStyle(
                              props.theme,
                              card
                            )}
                            cardStyle={CardListTypes[props.theme]}
                            ALL_FILTER_TEXT={ALL_FILTER_TEXT}
                          />
                        </div>
                      );
                    })}
                </div>
                <div className="text-center load-more-wrapper">
                  {props.DEVICE_VIEW.IsMobile &&
                    compState.cardsListToShow &&
                    compState.cardsListToShow.length > cardsToShow && (
                      <button
                        className={`btn btn-style-2`}
                        onClick={() => {
                          updateCardsToShow(
                            cardsToShow + NUMBER_OF_CARDS_TO_DISPLAY_ON_MOBILE
                          );
                        }}
                      >
                        Load More
                      </button>
                    )}
                </div>
              </>
            )}

          {/* Desktop View Products*/}
          {(isProductsPage || !props.categoryOrder) &&
            !props.DEVICE_VIEW.IsMobile &&
            CardListTypes[props.theme] === CardListTypes["Card Style 3"] && (
              <DesktopCardListView
                cards={compState.cardsListToShow}
                DEVICE_VIEW={props.DEVICE_VIEW}
                allSitePage={allSitePage}
                theme={props.theme}
                ALL_FILTER_TEXT={ALL_FILTER_TEXT}
              ></DesktopCardListView>
            )}

          {/* Show Categories */}
          {!isProductsPage && filteredCategory && (
            <div className={CardWrapperCSS[props.theme]}>
              {filteredCategory.map((card, index) => {
                return (
                  <div className="col card-col" key={index}>
                    <GenericCard
                      data={getTrimmedCardObjectByStyle(props.theme, card)}
                      DEVICE_VIEW={props.DEVICE_VIEW}
                      allSitePage={allSitePage}
                      cardStyle={CardListTypes[props.theme]}
                      ALL_FILTER_TEXT={ALL_FILTER_TEXT}
                    ></GenericCard>
                  </div>
                );
              })}
              {/* ) : (
          <div className="card-head">
            <h2 className="section-title has-top-line text-center">
              No categories available!
            </h2>
          </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
