import React from 'react';
import GenericCardListComp from "./genericcardlistcomp";
import GenericCardGallery from './genericcardgallery';
import { CardListTypes } from "../../assets/js/utils"
import { useStaticQuery, graphql } from "gatsby"
export const getTrimmedCardObjectByStyle = (cardStyle, card) => {
  let cardProps = { ...card };
  switch (CardListTypes[cardStyle]) {
      case CardListTypes["Card Style 1"]:
          delete cardProps.eyebrow;
          break;
      case CardListTypes["Card Style 2"]:
          delete cardProps.patternType;
          break;
      case CardListTypes["Card Style 3"]:
          delete cardProps.eyebrow;
          break;
      default:
          break;
  }
  return cardProps;
}
export default function GenericCardList(props) {
  let { showFilterMenu, displayAsGallerySlider, theme } = props;
  const { allSitePage } = useStaticQuery(
    graphql`
      query {
        allSitePage {
            nodes {
              path
              context{
                slug
              }
            }
          }
      }
    `);
  const getComponentClassName = () => {
    let clName = CardListTypes[theme];
    clName = clName + (displayAsGallerySlider ? " card-list card-gallery" : " card-list")
    if (showFilterMenu)
      clName = clName + " has-filter";
    return clName;
  }
  return (
    <section className={getComponentClassName()}>
        {!displayAsGallerySlider && <GenericCardListComp {...props} allSitePage={allSitePage}></GenericCardListComp>}
        {displayAsGallerySlider &&
          <GenericCardGallery {...props} allSitePage={allSitePage}></GenericCardGallery>
        }
    </section>
  )
}
